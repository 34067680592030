<template>
    <SecretarioNotas />
</template>
<script>
import SecretarioNotas from "@/components/SecretarioNotas.vue";
export default({
     components:{
        SecretarioNotas
    }
})
</script>
